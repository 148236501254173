import firebase from 'firebase/compat/app';
import "firebase/compat/auth";
import "firebase/compat/firestore";
import {getDoc, doc, setDoc} from 'firebase/firestore';
import {getAnalytics, setUserProperties} from "firebase/analytics";

// const app = firebase.initializeApp({
//     apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
//     authDomain: process.env.REACT_APP_FIREBASE_AUTH,
//     projectId: process.env.REACT_APP_FIREBASE_PROJ,
//     storageBucket: process.env.REACT_APP_FIREBASE_STORE,
//     messagingSenderId: process.env.REACT_APP_FIREBASE_SENDER_ID,
//     appId: process.env.REACT_APP_FIREBASE_APP_ID
// });

const app = firebase.initializeApp({
    apiKey: "AIzaSyDajhiFOkLCxCGxXEjltgrGQ9CVIQvZklg",
    authDomain: "wedding-2023-360322.firebaseapp.com",
    projectId: "wedding-2023-360322",
    storageBucket:"wedding-2023-360322.appspot.com",
    messagingSenderId: "367182081690",
    appId: "1:367182081690:web:ab870092b30e377095f923"
});

const firestore = firebase.firestore();
export const auth = app.auth();
export const analytics = getAnalytics(app);
export {setUserProperties};
export {firestore};
export {doc, getDoc, setDoc};
export default app;