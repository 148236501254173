import './App.css';
import Navbar from "./Navbar";
import Schedule from "./pages/Schedule";
import Photos from "./pages/Photos";
import Travel from "./pages/Travel";
import FAQs from "./pages/FAQs";
import Home from "./pages/Home";
import RSVP from "./pages/RSVP";
import {Route, Routes} from "react-router-dom";

function App() {

    return (
    <div>
      <Navbar/>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/Schedule" element={<Schedule />} />
        <Route path="/Travel" element={<Travel />} />
        <Route path="/Photos" element={<Photos />} />
        <Route path="/FAQs" element={<FAQs />} />
        <Route path="/RSVP" element={<RSVP />} />
      </Routes>
    </div>
  );
}

export default App;
