import tables from '../pictures/tables.jpg';
import {useEffect, useState} from "react";
import camera from '../pictures/camera.png';
import champagne from '../pictures/champagne.png';
import dinner from '../pictures/dinner.png';
import moon from '../pictures/moon.png';
import rings from '../pictures/rings.png';
import weddingdance from '../pictures/weddingdance.png';
import beachdayicon from '../pictures/beachdayicon.png';


export default function Schedule() {
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
    useEffect(() => {
        const handleWindowSizeChange = () => {
            if (window.innerWidth <= 768) {
                setIsMobile(true);
            } else {
                setIsMobile(false);
            }
            console.log("isMobile " + isMobile);
        }
        window.addEventListener("resize", handleWindowSizeChange)
        return () => {
            window.removeEventListener("resize", handleWindowSizeChange)
        }
    }, [isMobile]);

    // let scheduleDetails;
    // if (!isMobile) {
    //     scheduleDetails = <p>
    //                     <span>
    //                       <b>Flights:</b>
    //                     </span>
    //                     <div className="text-row-container">
    //                         <div className="text-row">
    //                             <div className="text-row-left-mini box">
    //                                 <p>
    //                                     <span>
    //                                     London ->
    //                                     </span>
    //                                 </p>
    //                             </div>
    //                             <div className="text-row-right-mini box">
    //                             <p>
    //                                 <span>
    //                                     Athens | Mykonos | Santorini
    //                                 </span>
    //                             </p>
    //                             </div>
    //                     </div>
    //                     </div>
    //                     </p>
    // }
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])
    return (
        <div className={'App'}>
            <div className="container">
                <img src={tables} className="Header-image" alt="header " />
                {!isMobile && <div className="centered-text-travel">So what's the plan?</div>}
            </div>
            <div className="text-row-title">The Day Before</div>
            <div className="text-row-container">

                <img src={champagne} className="title-image-center" alt="header " />
                <p>
                            <span>
                       Welcome Drinks
                                </span>
                    <span>
                       Funky Cedar in Antiparos Town
                                </span>
                    <span>
                       Dresscode: Summer evening attire. If it's breezy a light jacket is good to bring.
                                </span>
                    <span>
                          September 8, 8pm
                    </span>

                </p>
            </div>
            <div className="text-row-title">Wedding Day</div>
            <div className="text-row-container">
                <p>
            <span>
                          Fanari Beach Club
                    </span>
                <span>
                          Dresscode: Summer chic with a side of kilts. For the ladies, we recommend bringing comfortable shoes so you can change later on to dance or walk along beach to take pictures.
                    </span>
                </p>
            </div>
            <div className="text-row-container">
                <div className="text-row">
                    <div className={isMobile?"text-row-left-mobile box":"text-row-left box"}>
                        <img src={champagne} className="title-image-center" alt="header " />
                        <p>
                    <span>
                          Arrival
                    </span>
                            <span>
                          Time: 6:30pm
                    </span>
                        </p>
                    </div>
                    <div className={isMobile?"text-row-right-mobile box":"text-row-right box"}>
                        <img src={rings} className="title-image-center" alt="header " />
                        <p>
                            <span>
                       Ceremony
                                </span>
                            <span>
                       Time: around 7pm
                                </span>
                        </p>
                    </div>
                </div>
                <div className="text-row-container">
                    <div className="text-row">
                        <div className={isMobile?"text-row-left-mobile box":"text-row-left box"}>
                            <img src={camera} className="title-image-center" alt="header " />
                            <p>
                    <span>
                           Cocktails & Photos
                    </span>
                                <span>

                    </span>
                            </p>
                        </div>
                        <div className={isMobile?"text-row-right-mobile box":"text-row-right box"}>
                            <img src={dinner} className="title-image-center" alt="header " />
                            <p>
                            <span>
                       Dinner
                                </span>

                            </p>
                        </div>
                    </div>
                </div>
                <div className="text-row-container">
                    <div className="text-row">
                        <div className={isMobile?"text-row-left-mobile box":"text-row-left box"}>
                            <img src={weddingdance} className="title-image-center" alt="header " />
                            <p>
                    <span>
                           Dancing
                    </span>

                            </p>
                        </div>
                        <div className={isMobile?"text-row-right-mobile box":"text-row-right box"}>
                            <img src={moon} className="title-image-center" alt="header " />
                            <p>
                            <span>
                       Afterparty
                                </span>

                            </p>
                        </div>
                    </div>
                </div>
            </div>
            <div className="text-row-title">The Day After</div>
            <div className="text-row-container">

                <img src={beachdayicon} className="title-image-center" alt="header " />
                <p>
                            <span>
                       Hangover
                                </span>
                    <span>
                       September 10, Daytime
                                </span>
                </p>
            </div>
            <div className="text-row-container">

                        <img src={champagne} className="title-image-center" alt="header " />
                        <p>
                            <span>
                       Sunset Cocktails & Canapes
                                </span>
                            <span>
                       Sifneiko in Antiparos Town
                                </span>
                            <span>
                       Dresscode: Summer evening attire. If it's breezy a light jacket is good to bring.
                                </span>
                            <span>
                       September 10,  6PM
                                </span>
                        </p>
            </div>
        </div>
    )
}