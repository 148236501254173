import driving from '../pictures/driving.jpg'
import {useEffect, useState} from "react";
import airplane from '../pictures/airplane.ico'
import boat from '../pictures/boat.png'
import car from '../pictures/car.png';
import bus from '../pictures/bus.png';


export default function Travel() {
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
    useEffect(() => {
        const handleWindowSizeChange = () => {
            if (window.innerWidth <= 768) {
                setIsMobile(true);
            } else {
                setIsMobile(false);
            }
            console.log("isMobile " + isMobile);
        }
        window.addEventListener("resize", handleWindowSizeChange)
        return () => {
            window.removeEventListener("resize", handleWindowSizeChange)
        }
    }, [isMobile]);

    let travelPlanes;
    let travelFerries;
    if (!isMobile) {
        travelPlanes = <p>
                    <span>
                          <b>Flights:</b>
                    </span>
            <div className="text-row-container">
                <div className="text-row">
                    <div className="text-row-left-mini box">
                        <p>
                                    <span>
                                       London>
                                    </span>
                        </p>
                    </div>
                    <div className="text-row-right-mini box">
                        <p>
                                    <span>
                                        Athens | Mykonos | Santorini
                                    </span>
                        </p>
                    </div>
                </div>
            </div>
            <div className="text-row-container">
                <div className="text-row">
                    <div className="text-row-left-mini box">
                        <p>
                                    <span>
                                        Edinburgh>
                                    </span>
                        </p>
                    </div>
                    <div className="text-row-right-mini box">
                        <p>
                                    <span>
                                        Athens
                                    </span>
                        </p>
                    </div>
                </div>
            </div>
            <div className="text-row-container">
                <div className="text-row">
                    <div className="text-row-left-mini box">
                        <p>
                                    <span>
                                        Manchester>
                                    </span>
                        </p>
                    </div>
                    <div className="text-row-right-mini box">
                        <p>
                                    <span>
                                        Athens | Mykonos | Santorini
                                    </span>
                        </p>
                    </div>
                </div>
            </div>
            <div className="text-row-container">
                <div className="text-row">
                    <div className="text-row-left-mini box">
                        <p>
                                    <span>
                                        Athens>
                                    </span>
                        </p>
                    </div>
                    <div className="text-row-right-mini box">
                        <p>
                                    <span>
                                        Paros
                                    </span>
                        </p>
                    </div>
                </div>
            </div>
            <div className="text-row-container">
                <div className="text-row">
                    <div className="text-row-left-mini box">
                        <p>
                                    <span>

                                    </span>
                        </p>
                    </div>
                    <div className="text-row-right-mini box">
                        <p>
                                    <span>

                                    </span>
                        </p>
                    </div>
                </div>
            </div>
        </p>
        travelFerries = <p>
                    <span>
                          <b>Ferries:</b>
                    </span>
            <div className="text-row-container">
                <div className="text-row">
                    <div className="text-row-left-mini box">
                        <p>
                                    <span>
                                       Pireaus>
                                    </span>
                        </p>
                    </div>
                    <div className="text-row-right-mini box">
                        <p>
                                    <span>
                                        Paros
                                    </span>
                        </p>
                    </div>
                </div>
            </div>
            <div className="text-row-container">
                <div className="text-row">
                    <div className="text-row-left-mini box">
                        <p>
                                    <span>
                                        Rafina>
                                    </span>
                        </p>
                    </div>
                    <div className="text-row-right-mini box">
                        <p>
                                    <span>
                                        Paros
                                    </span>
                        </p>
                    </div>
                </div>
            </div>
            <div className="text-row-container">
                <div className="text-row">
                    <div className="text-row-left-mini box">
                        <p>
                                    <span>
                                        Mykonos>
                                    </span>
                        </p>
                    </div>
                    <div className="text-row-right-mini box">
                        <p>
                                    <span>
                                        Paros
                                    </span>
                        </p>
                    </div>
                </div>
            </div>
            <div className="text-row-container">
                <div className="text-row">
                    <div className="text-row-left-mini box">
                        <p>
                                    <span>
                                        Santorini>
                                    </span>
                        </p>
                    </div>
                    <div className="text-row-right-mini box">
                        <p>
                                    <span>
                                        Paros
                                    </span>
                        </p>
                    </div>
                </div>
            </div>
            <div className="text-row-container">
                <div className="text-row">
                    <div className="text-row-left-mini box">
                        <p>
                                    <span>
                                        Paros>
                                    </span>
                        </p>
                    </div>
                    <div className="text-row-right-mini box">
                        <p>
                                    <span>
                                        Antiparos
                                    </span>
                        </p>
                    </div>
                </div>
            </div>
        </p>
    }
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])
    return (
        <div className={'App'}>
            <div className="container">
                <img src={driving} className="Header-image" alt="header " />
                {!isMobile && <div className="centered-text-travel">Planes, boats, and Kaitlyn driving!</div>}
            </div>
            <div className="text-row-title">Getting to Antiparos</div>
            <div className="text-row-container">
                <div className="text-row">
                    <div className="text-row-center box">
                        <p>
                            <span>
                                Antiparos is a beautiful island in the Cyclades. It is joined to Paros via frequent ferries. There is a ferry every 30 minutes leaving from Pounda port in Paros heading over to Antiparos, and it is only a 5min boatride. You should plan travel to Paros island, and from there getting to Antiparos is easy.
                                Paros has both a port and an airport, so you can arrive by boat or plane.
                            </span>
                        </p>
                    </div>
                </div>
            </div>

    <div className="text-row-container">
        <div className="text-row">
            <div className={isMobile?"text-row-left-mobile box":"text-row-left box"}>
                <img src={airplane} className="title-image-center" alt="header " />
                <p>
                    <span>
                          If travelling from the UK, you may be able to fly direct to Mykonos or Santorini. From there, Paros is a brief hour-long ferry trip.
                        Alternatively, you can fly to Athens, and get a connecting flight directly to Paros Airport.
                    </span>
                </p>
                {travelPlanes}
            </div>
            <div className={isMobile?"text-row-right-mobile box":"text-row-right box"}>
                <img src={boat} className="title-image-center" alt="header " />
                <p>
                            <span>
                       The easiest way to book your ferry is to download the Ferryhopper app.
                                Travelling from Athens, you can get a ferry to Paros either from Pireaus Port or Rafina Port.

                                </span>
                </p>
                {travelFerries}
            </div>
        </div>
        <div className="text-row-container">
            <div className="text-row">
                <div className={isMobile?"text-row-left-mobile box":"text-row-left box"}>
                    <img src={car} className="title-image-center" alt="header " />
                    <p>
                    <span>
                          If you want to rent a car or motorbike / quadbike on the island, there are a lot of options. If you rent from Paros, it will cost you 10 euro to take it on the ferry over to Antiparos.
                    </span>
                    </p>
                </div>
                <div className={isMobile?"text-row-right-mobile box":"text-row-right box"}>
                    <img src={bus} className="title-image-center" alt="header " />
                    <p>
                            <span>
                       If you don't fancy renting, there are plenty of options to move around via public transport. There is a bus every 30 minutes travelling from Parikia to Pounda port. From Pounda port you can take the 5 min ferry to Antiparos.

                                </span>
                    </p>
                </div>
            </div>
        </div>
        </div>
            <div className="text-row-title">It's a long journey but it will be well worth it!</div>
        </div>
    )
}