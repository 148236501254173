import {Link, useMatch, useResolvedPath} from "react-router-dom";
import React, { useEffect, useRef, useState} from "react";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faBars} from '@fortawesome/free-solid-svg-icons'


export default function Navbar() {
    const [navBackground, setNavBackground] = useState(window.location.href.includes("Photos")?'nav-solid':'nav-transparent');
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
    const [showMenu, setShowMenu] = useState(false);
    const navRef = useRef(null);
    let menu;
    if (showMenu) {
        menu = (
            <nav className="nav-menu">
                <ul>
                        <CustomLink style={{fontFamily: "'Bohemian Cassidy Free', sans-serif", borderBottom:"2px solid black", fontSize:"4vh", textAlign:'left'}} onClick={() => {setShowMenu(false);}} to="/">Home </CustomLink>
                        <CustomLink style={{fontFamily: "'Bohemian Cassidy Free', sans-serif", borderBottom:"2px solid black", fontSize:"4vh", textAlign:'left'}} onClick={() => {setShowMenu(false);}} to="/Schedule">Schedule</CustomLink>
                        <CustomLink style={{fontFamily: "'Bohemian Cassidy Free', sans-serif", borderBottom:"2px solid black", fontSize:"4vh", textAlign:'left'}} onClick={() => {setShowMenu(false);}} to="/Travel">Travel</CustomLink>
                        <CustomLink style={{fontFamily: "'Bohemian Cassidy Free', sans-serif", borderBottom:"2px solid black", fontSize:"4vh", textAlign:'left'}} onClick={() => {setShowMenu(false);}} to="/Photos">Photos</CustomLink>
                        <CustomLink style={{fontFamily: "'Bohemian Cassidy Free', sans-serif", borderBottom:"2px solid black", fontSize:"4vh", textAlign:'left'}} onClick={() => {setShowMenu(false);}} to="/FAQs">Questions</CustomLink>
                        <CustomLink style={{fontFamily: "'Bohemian Cassidy Free', sans-serif", borderBottom:"2px solid black", fontSize:"4vh", textAlign:'left', backgroundColor:'lightblue'}} onClick={() => {setShowMenu(false);}} to="/RSVP">RSVP</CustomLink>
                </ul>
            </nav>
        )
    }

    const handleScroll = () => {
        const show = window.scrollY > 300;
        const onPhoto = window.location.href.includes("Photos");
        if (show || onPhoto) {
            setNavBackground('nav-solid');
            console.log("show " + window.scrollY + " " + show);
        } else {
            setNavBackground('nav-transparent');
            console.log("show " + window.scrollY + " " + show);
        }
    }


    useEffect(() => {
            window.addEventListener("scroll", handleScroll);
        return () => {
            window.removeEventListener("scroll", handleScroll)
        }
    }, [navBackground]);

    useEffect(() => {
        const handleWindowSizeChange = () => {
            if (window.innerWidth <= 768) {
                setIsMobile(true);
            } else {
                setIsMobile(false);
            }
            console.log("isMobile " + isMobile);
        }
            window.addEventListener("resize", handleWindowSizeChange)
        return () => {
            window.removeEventListener("resize", handleWindowSizeChange)
        }
    }, [isMobile]);

    return (
        <div ref={navRef}>
            <nav className={navBackground}>
                <Link to="/" className="site-title">Kaitlyn & Asteris</Link>
                <CustomBar isMobile={isMobile} setShowMenu={setShowMenu} showMenu={showMenu} />
            </nav>
            {menu}
        </div>
    )
}

function CustomLink({to, children,...props}) {
    const resolvedPath = useResolvedPath(to);
    const isActive = useMatch({path: resolvedPath.pathname, end: true})
        return (
        <li className={isActive? "active" : ""}>
            <Link to={to} {...props}>{children}</Link>
        </li>
    )
}

function CustomBar({isMobile,setShowMenu,showMenu}) {
    if (isMobile) {
        return (
        <ul>
            <FontAwesomeIcon size="lg" className="menu-icon"
                             icon={faBars}
                             onClick={() =>setShowMenu(!showMenu)}
            />
        </ul>
        );
    } else {
        return (
            <ul>
                <CustomLink to="/Schedule">Schedule</CustomLink>
                <CustomLink to="/Travel">Travel</CustomLink>
                <CustomLink to="/Photos">Photos</CustomLink>
                <CustomLink to="/FAQs">FAQs</CustomLink>
                <CustomLink to="/RSVP">RSVP</CustomLink>
            </ul>
        );
    }

}