
import {useEffect, useState} from "react";
import selfie1 from '../pictures/picture2.jpg';
import selfie2 from '../pictures/picture14.jpg';
import selfie3 from '../pictures/picture15.jpg';
import selfie4 from '../pictures/picture16.jpg';
import selfie5 from '../pictures/picture17.jpg';
import selfie6 from '../pictures/picture18.jpg';
import selfie7 from '../pictures/picture19.jpg';
import selfie8 from '../pictures/picture1.jpg';
import selfie9 from '../pictures/picture3.jpg';
import selfie10 from '../pictures/picture8.jpg';
import selfie11 from '../pictures/picture11.jpg';
import selfie12 from '../pictures/picture13.jpg';
import camera from "../pictures/camera.png";
import loveheart from "../pictures/loveheart.png";

export default function Photos() {
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
    useEffect(() => {
        const handleWindowSizeChange = () => {
            if (window.innerWidth <= 768) {
                setIsMobile(true);
            } else {
                setIsMobile(false);
            }
            console.log("isMobile " + isMobile);
        }
        window.addEventListener("resize", handleWindowSizeChange)
        return () => {
            window.removeEventListener("resize", handleWindowSizeChange)
        }
    }, [isMobile]);

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    return (
        <div className={'App'}>
            <div style={{paddingTop:'40px'}} />
            <div className="text-row-title">Photos</div>
            <img src={camera} className="title-image-center" alt="header " />
            <div className="text-row-container-photos">
                <div className="">
                    <div className={isMobile?"text-row-left-mobile box":"photo-row box"}>
                        <img src={selfie1} className="photo-page-image" alt="header " />
                    </div>
                    <div className={isMobile?"text-row-left-mobile box":"photo-row box"}>
                        <img src={selfie2} className="photo-page-image" alt="header " />
                    </div>
                    <div className={isMobile?"text-row-left-mobile box":"photo-row box"}>
                        <img src={selfie3} className="photo-page-image" alt="header " />
                    </div>
                    <div className={isMobile?"text-row-left-mobile box":"photo-row box"}>
                        <img src={selfie4} className="photo-page-image" alt="header " />
                    </div>
                </div>
            </div>
            <div className="text-row-container-photos">
                <div className="">
                    <div className={isMobile?"text-row-left-mobile box":"photo-row box"}>
                        <img src={selfie5} className="photo-page-image" alt="header " />
                    </div>
                    <div className={isMobile?"text-row-left-mobile box":"photo-row box"}>
                        <img src={selfie6} className="photo-page-image" alt="header " />
                    </div>
                    <div className={isMobile?"text-row-left-mobile box":"photo-row box"}>
                        <img src={selfie7} className="photo-page-image" alt="header " />
                    </div>
                    <div className={isMobile?"text-row-left-mobile box":"photo-row box"}>
                        <img src={selfie8} className="photo-page-image" alt="header " />
                    </div>
                </div>
            </div>
            <div className="text-row-container-photos">
                <div className="">
                    <div className={isMobile?"text-row-left-mobile box":"photo-row box"}>
                        <img src={selfie9} className="photo-page-image" alt="header " />
                    </div>
                    <div className={isMobile?"text-row-left-mobile box":"photo-row box"}>
                        <img src={selfie10} className="photo-page-image" alt="header " />
                    </div>
                    <div className={isMobile?"text-row-left-mobile box":"photo-row box"}>
                        <img src={selfie11} className="photo-page-image" alt="header " />
                    </div>
                    <div className={isMobile?"text-row-left-mobile box":"photo-row box"}>
                        <img src={selfie12} className="photo-page-image" alt="header " />

                    </div>
                </div>
            </div>
            <img src={loveheart} className="title-image-center" alt="header " />
        </div>
    )
}