import cat from "../pictures/PXL_20220907_093145267_2_2.jpg";
import questionmark from "../pictures/questionmark.webp";
import React, {useEffect, useState} from "react";

export default function FAQs() {

    const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
    useEffect(() => {
        const handleWindowSizeChange = () => {
            if (window.innerWidth <= 768) {
                setIsMobile(true);
            } else {
                setIsMobile(false);
            }
            console.log("isMobile " + isMobile);
        }
        window.addEventListener("resize", handleWindowSizeChange)
        return () => {
            window.removeEventListener("resize", handleWindowSizeChange)
        }
    }, [isMobile]);

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    return (
        <div className={'App'}>
            <div className="container">
                <img src={cat} className="Header-image" alt="header " />
                {!isMobile && <div className="centered-text-travel">As per my previous email...</div>}
            </div>
            <div className="text-row-title">Q&A</div>
            <img src={questionmark} className="title-image-center" alt="header " />
            <div className="text-row-question">What days do I need to be in Antiparos?</div>
            <div className="text-row-container">
                <div className="text-row">
                    <div className="text-row-faqs box">
                        <p>
                            <span>
                                We recommend arriving in Paros/Antiparos at least the day before so you can get settled before the 3 nights of wedding events.
                                After those days, you can enjoy your holiday or see what other guests are doing. We expect less organised/optional plans will continue for a few days after for those around (i.e hiking, beach days, boat trip). We will take a break from organising
                            </span>
                        </p>
                    </div>
                </div>
            </div>
            <div className="text-row-question">Where should I stay?</div>
            <div className="text-row-container">
                <div className="text-row">
                    <div className="text-row-faqs box">
                        <p>
                            <span>
                                Ideally stay in Antiparos Town, all the venues are only a 5 minute walk away!
                            </span>
                        </p>
                    </div>
                </div>
            </div>
            <div className="text-row-question">Can I smash plates?</div>
            <div className="text-row-container">
                <div className="text-row">
                    <div className="text-row-faqs box">
                        <p>
                            <span>
                                Please do not smash plates at our wedding (mainly to the Scots!)
                             </span>
                        </p>
                    </div>
                </div>
            </div>
            <div className="text-row-question">Can I stay on Paros and travel to Antiparos on the day?</div>
            <div className="text-row-container">
                <div className="text-row">
                    <div className="text-row-faqs box">
                        <p>
                            <span>
                                Yes, Paros has many more hotels and things to do so you could absolutely stay on Paros and travel. See travel page for more information on ferry to and from Antiparos to Paros (you will need cash to pay for boat)
                             </span>
                        </p>
                    </div>
                </div>
            </div>
            <div className="text-row-question">When will the nights end?</div>
            <div className="text-row-container">
                <div className="text-row">
                    <div className="text-row-faqs box">
                        <p>
                            <span>
                                 Wedding: When the sun comes up!
                             </span>
                            <span>
                                 Day After: More relaxed event which can last as long as we wish!
                             </span>
                        </p>
                    </div>
                </div>
            </div>
            <div className="text-row-question">Can I wear a kilt if I am not Scottish?</div>
            <div className="text-row-container">
                <div className="text-row">
                    <div className="text-row-faqs box">
                        <p>
                            <span>
                                Absolutely, wear whatever you feel is most comfortable to you!
                             </span>
                        </p>
                    </div>
                </div>
            </div>
            <div className="text-row-question">What is the dresscode for the wedding events?</div>
            <div className="text-row-container">
                <div className="text-row">
                    <div className="text-row-faqs box">
                        <p>
                            <span>
                                Wedding: Summer chic with a side of kilts.
                             </span>
                            <span>
                                Day After: Relaxed summer attire
                             </span>
                        </p>
                    </div>
                </div>
            </div>
            <div className="text-row-question">What will be on the menu?</div>
            <div className="text-row-container">
                <div className="text-row">
                    <div className="text-row-faqs box">
                        <p>
                            <span>
                                Selection of buffet food will be provided at ~9:30pm so you can fill your plates. Since the wedding is starting later in the day we recommend having a BIG lunch! There will also be a late night snack to soak up all of the alcohol and refuel your dancing feet. Please tell us on RSVP if there are any dietary considerations we need to be aware of.
                             </span>
                        </p>
                    </div>
                </div>
            </div>
            <div className="text-row-question">Do I need to bring cash or will places take card?</div>
            <div className="text-row-container">
                <div className="text-row">
                    <div className="text-row-faqs box">
                        <p>
                            <span>
                                If you are taking taxis in Paros/Antiparos you will need cash. Most restaurants and cafes take card or cash now. Cash is required for tips (~10% is normal).
For the 2 wedding events all catering and drinks will be covered.
                             </span>
                        </p>
                    </div>
                </div>
            </div>
            <div className="text-row-question">Can I get my hair and makeup done before the wedding?</div>
            <div className="text-row-container">
                <div className="text-row">
                    <div className="text-row-faqs box">
                        <p>
                            <span>
                                There are a number of options on Paros and Antiparos for you to arrange. You can search on instagram.
                             </span>
                        </p>
                    </div>
                </div>
            </div>
            <div className="text-row-question">Where can I stay?</div>
            <div className="text-row-container">
                <div className="text-row">
                    <div className="text-row-faqs box">
                        <p>
                            <span>
                                Check booking.com for ideas on where to stay on Paros or Antiparos. Paros is connected to many other islands in the Cyclades so it's easy for you to continue to explore Greece and enjoy your time!
                             </span>
                        </p>
                    </div>
                </div>
            </div>
            <div className="text-row-question">Things to do?</div>
            <div className="text-row-container">
                <div className="text-row">
                    <div className="text-row-faqs box">
                        <p>
                            <span>
                                -Boat trips
                             </span>
                            <span>
                                -Sunrise hikes (ask your hotel where!)
                             </span>
                            <span>
                               -Visit beaches
                             </span>
                            <span>
                               -Visit neighbouring Islands
                             </span>
                            <span>
                               -Eat as much Tzatziki as you possibly can
                             </span>
                        </p>
                    </div>
                </div>
            </div>
            <div className="text-row-question">What if I cannot attend?</div>
            <div className="text-row-container">
                <div className="text-row">
                    <div className="text-row-faqs box">
                        <p>
                            <span>
                                We have invited our nearest and dearest family and friends to our wedding weekend. We understand if you cannot join as it is abroad and we look forward to celebrating when we return!
                             </span>
                        </p>
                    </div>
                </div>
            </div>
            <div className="text-row-question">Do you have a gift registry?</div>
            <div className="text-row-container">
                <div className="text-row">
                    <div className="text-row-faqs box">
                        <p>
                            <span>
                                The gift of you joining us is enough for us so please do not feel the need to give us anything! We have been living together for a few years and have collected lovely things for our home. However,
if you feel an urge and will not take no for an answer and want to give us something or transfer money you can use these details:
                             </span>
                            <span>
                                Name: Kaitlyn Scott
                                </span>
                            <span>
                               IBAN: GB28 MONZ 0400 0477 4311 56
                                </span>
                            <span>
                                SEDOL: MONZGB2L
                                </span>
                        </p>
                    </div>
                </div>
            </div>


        </div>
    )
}