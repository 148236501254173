import React, {useRef, useContext, useState, useEffect} from 'react';
import {Form, Button, Card} from 'react-bootstrap';
import boat from "../pictures/PXL_20220908_132007572_2.jpg";
import {auth} from '../firebase.js';
import {firestore} from '../firebase.js';
import {doc, getDoc, setDoc} from '../firebase.js';
import letter from "../pictures/letter.png";


export default function RSVP() {
     const [error, setError] = useState('');
     const [loading, setLoading] = useState(false);
     const [didSubmit, setDidSubmit] = useState(false);
     //  const [invitees, setInvitees] = useState([]);
     const [currentUser , setCurrentUser] = useState();
     const [currentUserEmail , setCurrentUserEmail] = useState('');
     const [currentUserAttending, setCurrentUserAttending] = useState(false);
     const [currentUserAttendingTemp, setCurrentUserAttendingTemp] = useState(false);
     const [currentUserDiet, setCurrentUserDiet] = useState("");
     const [currentUserPlus1, setCurrentUserPlus1] = useState("");
     const [loggedIn, setLoggedIn] = useState(false);
     const AuthContext = React.createContext();
     let emailRef = useRef();
     let passwordRef = useRef();
     let withWhoRef = useRef();
     let dietRef = useRef();

    const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
    useEffect(() => {
        const handleWindowSizeChange = () => {
            if (window.innerWidth <= 768) {
                setIsMobile(true);
            } else {
                setIsMobile(false);
            }
            console.log("isMobile " + isMobile);
        }
        window.addEventListener("resize", handleWindowSizeChange)
        return () => {
            window.removeEventListener("resize", handleWindowSizeChange)
        }
    }, [isMobile]);



    useContext(AuthContext);
     function login (email, password) {
         return auth.signInWithEmailAndPassword(email, password);
     }
     function logout() {
         return auth.signOut();
     }
     useEffect(() => {
            const unsubscribe = auth.onAuthStateChanged(user => {
                setCurrentUser(user);
                if (user!=null) {
                    setLoggedIn(true);
                }
  //              console.log("set current user to :" + currentUser.email);
                let docRef;
                if (currentUser) {
                    docRef = doc(firestore, 'invitees', currentUser.email);
                    getDoc(docRef).then((doc) => {
                        setCurrentUserAttending(doc.data().attending);
                        setCurrentUserDiet(doc.data().diet)
                        setCurrentUserPlus1(doc.data().plus1)
                        setCurrentUserEmail(currentUser.email);
                        if (currentUser.email) {
                            setDidSubmit(true);
                        }
                        console.log(doc.data(), doc.id)
                    })
                }
                return unsubscribe;
            })
        }, [currentUser])

    async function handleSubmit(e) {
        e.preventDefault();
        console.log("Attempting login with user: " + emailRef.current.value + " password: " + passwordRef.current.value);
        try {
            setError('')
            setLoading(true)
            await login(emailRef.current.value, passwordRef.current.value);
            setLoggedIn(true);
            setCurrentUserEmail(emailRef.current.value);
        } catch (e) {
            setError('Failed to Login ' + e);
            console.log('Failed to login, login not work' + e);
        }
        setLoading(false);
        console.log("Submitted button click");

    }

    async function handleLogout(e) {
        e.preventDefault();
        console.log("Attempting logout user: " + currentUser.email.value);
        try {
            setError('')
            setLoading(true)
            await logout();
            setLoggedIn(false);
            setCurrentUser("");
            setCurrentUserAttending(false);
            setCurrentUserEmail('');
            setCurrentUserDiet('');
            setCurrentUserPlus1('');
            setDidSubmit(false);
        } catch (e) {
            setError('Failed to Logout ' + e);
            console.log('Failed to logout' + e);
        }
        setLoading(false);
        console.log("Submitted button click");
    }

    async function handleChangeOfPlans(e) {
        e.preventDefault();
        setCurrentUserAttending(false);
        setDidSubmit(false);
        setLoading(false);
        console.log("Submitted button click");
    }


    async function handleSubmitRSVP(e) {
        e.preventDefault();
        setError('');
        setLoading(true);
        console.log("Submitted RSVP with " + currentUserAttendingTemp + " diet: " +dietRef.current.value);
        const isAttending = currentUserAttendingTemp;
        try {
            const docRef = doc(firestore, 'invitees', currentUserEmail);
            await setDoc(docRef, {
                email:currentUserEmail,
                attending: isAttending,
                plus1: withWhoRef.current.value,
                diet: dietRef.current.value
            });
            setDidSubmit(true);
            setCurrentUserAttending(isAttending);
            setCurrentUserDiet(dietRef.current.value);
            setCurrentUserPlus1(withWhoRef.current.value);
        } catch (e) {
            setError('Failed to set user preferences ' + e);
            console.log('Failed to set user preferences' + e);
        }
        setLoading(false);
        console.log('Submitted user preferences' + e);
    }

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    function handleSelectChange(e) {
         console.log("select change: " + e.currentTarget.value);
         if (e.currentTarget.value==="Yes") {
             setCurrentUserAttendingTemp(true);
             console.log("set attending to true");
         } else {
             setCurrentUserAttendingTemp(false);
         }
    }

    return (
        <div className={'App'}>
            <div className="container">
                <img src={boat} className="Header-image" alt="header " />
                {!isMobile && <div className="centered-text-travel">We hope to see you there!</div> }
            </div>
            <div className="text-row-title">Let us know if you're coming!</div>
            <img src={letter} className="title-image-center" alt="header " />
            <div className="text-row-container">
                <div className="text-row">
                    <div className="text-row-center box">
                        <p>
                            <span>
                                Please RSVP below with your email and the password we sent you! You'll also get a chance to note down any dietary or other requirements we should know about before the wedding.
                            </span>
                        </p>
                    </div>
                </div>
            </div>
            <Card className={"bg-dark"} style={{minWidth:'100%', color:'black', fontSize:'large', fontFamily:"'Bellerose', sans-serif", padding:'10px'}}>
                <Card.Body style={{display:'inline-grid'}}>
                    {error && <div>Sorry can't find an account for that email, try the one we emailed you on</div>}
                    {currentUser && currentUser.email}
                    {!loggedIn &&
                        <Form style={{display:'inline-grid', paddingBottom:'10px'}} onSubmit={handleSubmit}>
                            <Form.Group style={{display:'inline-grid'}} className="mb-3" id="email">
                                <Form.Label>Email</Form.Label>
                                <Form.Control style={{height:'5vh',minWidth:'30vh'}} type="email" ref={emailRef} required/>
                            </Form.Group>
                            <Form.Group style={{display:'inline-grid', paddingBottom:'10px'}} id="email">
                                <Form.Label>Password</Form.Label>
                                <Form.Control style={{height:'5vh', minWidth:'30vh%'}} type="password" ref={passwordRef} required/>
                            </Form.Group>
                            <Button style={{padding:'10px', color:'black',backgroundColor:'lightblue',fontWeight:'bold',borderColor:'lightblue'}} variant="primary" type="submit" disabled={loading} className="">RSVP</Button>
                        </Form>
                    }
                    {loggedIn && currentUserAttending &&
                        <div className="text-row-container">
                            <div className="text-row">
                                <div className="text-row-center box">
                                    <p>
                                        <span>
                                            You are attending!
                                        </span>
                                        <span>
                                            Your diet preferences are {currentUserDiet}
                                        </span>
                                        <span>
                                            You are coming with {currentUserPlus1}
                                        </span>
                                    </p>
                                    <p>
                                        <span>
                                            If you have any questions feel free to contact us!
                                        </span>
                                        <span>
                                            Asteris: +44 7879315794
                                        </span>
                                        <span>
                                            Kaitlyn: +44 7585339302
                                        </span>
                                    </p>
                                </div>
                            </div>
                        </div>
                    }
                    {loggedIn && !currentUserAttending && didSubmit &&
                        <div className="text-row-container">
                            <div className="text-row">
                                <div className="text-row-center box">
                                    <p>
                                        <span>
                                            We're sorry you can't make it!
                                        </span>
                                        <span>
                                           Thanks for letting us know
                                        </span>
                                    </p>
                                </div>
                            </div>
                        </div>
                    }
                    {loggedIn && !currentUserAttending && !didSubmit &&
                        <Form style={{display:'inline-grid', paddingBottom:'10px'}} onSubmit={handleSubmitRSVP}>
                            <Form.Group style={{display:'inline-grid'}} id="attending">
                                <Form.Label>Will you be attending this wedding?</Form.Label>
                                <select onChange={handleSelectChange} size="1" style={{padding:'10px',color:'black',backgroundColor:'white',fontWeight:'bold',borderColor:'lightblue'}} className="form-control" id="exampleFormControlSelect1">
                                    <option></option>
                                    <option>Yes</option>
                                    <option>Sorry, I can't attend</option>
                                </select>
                            </Form.Group>
                            <Form.Group style={{display:'inline-grid'}} id="withWho">
                                <Form.Label>With Who will you attend?</Form.Label>
                                <Form.Control style={{height:'5vh', width:'30vh'}} ref={withWhoRef}/>
                            </Form.Group>
                            <Form.Group style={{display:'inline-grid'}} id="diet">
                                <Form.Label>Any dietary requirements?</Form.Label>
                                <Form.Control style={{height:'5vh', width:'30vh'}} ref={dietRef} required/>
                            </Form.Group>
                            <Button style={{color:'black',backgroundColor:'lightblue',fontWeight:'bold',borderColor:'lightblue'}} type="submit" disabled={loading} className="">Submit</Button>
                        </Form>
                    }
                    {loggedIn && currentUserAttending &&
                        <Button style={{padding:'10px',color:'black',backgroundColor:'chocolate',fontWeight:'bold',borderColor:'lightblue'}} type="button" onClick={handleChangeOfPlans} disabled={loading}
                                className="">Change of plans?</Button>
                    }
                    <Button style={{padding:'10px',color:'black',backgroundColor:'darkgray',fontWeight:'bold',borderColor:'lightblue'}} type="button" onClick={handleLogout} disabled={!loggedIn} className="">Logout</Button>
                </Card.Body>
            </Card>
            <div className="text-row-container">
                <div className="text-row">
                    <div className="text-row-center box">
                        <p>
                            <span>

                            </span>
                        </p>
                    </div>
                </div>
            </div>

        </div>
    )
}