import transparentflower2 from "../pictures/transparentflower2.png";
import selfiesunset from "../pictures/selfiesunset.jpg";
import bgn from "../pictures/BWBEACH.jpg";
import olivebranch from "../pictures/olivebranch.png";
import loveheart from "../pictures/loveheart.png";
import {useEffect, useState} from "react";
import Timer from "./Timer.js";

export default function Home() {

    const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
    useEffect(() => {
        const handleWindowSizeChange = () => {
            if (window.innerWidth <= 768) {
                setIsMobile(true);
            } else {
                setIsMobile(false);
            }
            console.log("isMobile " + isMobile);
        }
        window.addEventListener("resize", handleWindowSizeChange)
        return () => {
            window.removeEventListener("resize", handleWindowSizeChange)
        }
    }, [isMobile]);

    let leftFlower;
    let rightFlower;
    if (!isMobile) {
        leftFlower = <img src={olivebranch} className="title-image-right" alt="header " />
        rightFlower = <img src={transparentflower2} className="title-image-left" alt="header " />
    }

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    return <div className="App">
        <header className="App-header">
            <div className="container">
                <img src={bgn} className="Header-image" alt="header " />
                <div className="centered-text-travel">
                    <Timer />
                </div>
            </div>
            <div className="post-container">
                {leftFlower}
                <div className="post-content">
                    <div style={{paddingTop:'40px'}} />
                    <div className="intro-title">Asteris Stagkos Bell</div>
                    <div className="intro-and">&</div>
                    <div className="intro-title">Kaitlyn Scott</div>
                    <div style={{paddingTop:'40px'}} />
                    <div className="text-row-title">Our Wedding in Antiparos, Greece </div>
                    <div className="intro-month">September 9, 2023</div>
                </div>
                {rightFlower}
            </div>
            <p>
            <span>

            </span>
            </p>
            <img src={loveheart} className="title-image-center" alt="header " />
            <div className="text-row-title">Once Upon a Time in Scotland... </div>
            <div className="text-row-container">
                <div className="text-row">
                    <div className={isMobile?"text-row-left-mobile box":"text-row-left box"}>
                        <p>
                            <span>
                                It's August 2016, it's the BlackRock summer party, and I'm looking as good as I can in my over-sized specs, baggy gray suit, and wispy mustache.
                                But I guess it was the Edinburgh Fringe festival and I was as confident as the brave (terrible) comedians on the free shows.
                                I look over at the bar and see a gorgeous blonde girl. So of course I walk up, and tell her that I'm one of the new interns (I wasn't). And the rest is history, sort of.
                            </span>
                            <span>
                            - Asteris -
                            </span>
                        </p>
                    </div>
                    <div className={isMobile?"text-row-right-mobile box":"text-row-right box"}>
                        <p>
                            <span>
                        I was working as a HR grad standing in a cocktail bar....when I met Asteris, a cute Greek guy from ACS. We partied and had a good night. However, the next day I couldn't find the 'cute Greek guy' on work platform or social media as I thought he was called Azeris. Luckily for us we had lots of mutual friends and we worked in the same building so our paths would continue to cross!
                                </span>
                            <span>- Kaitlyn -
                            </span>
                        </p>
                    </div>
                </div>
            </div>
            <div className="text-row-title"> The Proposal </div>
            <div className="text-row-container">
                <div className="text-row">
                    <div className={isMobile?"text-row-left-mobile box":"text-row-left box"}>
                        <p>
                            <span>
                                It was after spending weeks in isolation talking to nobody but each other that I realised I was having a great time, and I knew, I just knew that we were perfect for each other. But I patiently waited... until we had moved and settled into our first home together, almost 2 years later.
                                So it all started with an idea, that I should propose in our home, and surprise her by learning how to play our favourite song on the Piano. Adami and I then put together the plan...
                                I would take Kaitlyn out for her birthday, and while we were out he would come home and setup the place with roses, balloons, flowers, candles.
                                Other than  that I had to buy a piano, and then secretly learn how to play 'To Build a Home'. Luckily Kaitlyn went to Lisbon for a weekend which gave me the perfect chance to practice. Oh, and I had to secretly buy a ring of course, the 4 Cs and all that.
                                So the day of the plan comes along, and I've been nervous all week, its game time.
                            </span>
                            <span>
                            - Asteris -
                            </span>
                        </p>
                    </div>
                    <div className={isMobile?"text-row-right-mobile box":"text-row-right box"}>
                        <p>
                            <span>
                        Picture this, it's my birthday weekend and I am excited for surprise plans. Asteris always books surprise activities for special celebrations so nothing out of the ordinary this time. We woke up and he took me to brunch (I LOVE BRUNCH!) so he was doing well. Afterwards, we went to an amazing Spa and luckily he gave me warning on the day to pack a bikini. I headed home feeling relaxed while he headed home nervous (little did I know!). He asked me to wait outside before entering the flat, I figured he bought me a birthday cake and needed to light some candles. When I was allowed to come in I saw red rose petals all over the floor..(is this still for my birthday I wondered?). Step by step I got closer to the living room where he ran over and played our song on the piano...! For 2 minutes I sobbed alone unsure if the candles, roses and rose petals were for my birthday or dare I think something else. He proposed with a ring he chose and we ugly cried happy tears together for a good hour before dinner!
                                  </span>
                            <span>
                           - Kaitlyn -
                            </span>
                        </p>
                    </div>
                </div>
            </div>
            <div className="text-row-title"> Our Wedding </div>
            <div className="text-row-container">
                <div className="text-row">
                    <div className={isMobile?"text-row-left-mobile box":"text-row-left box"}>
                        <p>
                            <span>
                                Antiparos is such a special place to us. Asteris camped there every summer as a child, living freely and making lifelong friends.
                                When we eventually went together in 2021 and then again in 2022 - we explored old memories and made new ones; We felt relaxed and excited and in love.
                                We cannot wait to go back there with all of the most important people in our lives!
                            </span>
                            <span>
                            - Asteris & Kaitlyn -
                            </span>
                        </p>
                    </div>
                </div>
            </div>
            <img src={loveheart} className="title-image-center" alt="header " />
            <div className="container">
                <img src={selfiesunset} className="Header-image" alt="header " />
                <div className="centered-text"></div>
            </div>
        </header>
    </div>
}